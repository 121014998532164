import React from "react";
import { Box, Paper } from "@material-ui/core";
import { Field } from "formik";
import useStyles from "./style";
import InputLoading from "../InputLoading";
import { DatePicker } from "formik-material-ui-pickers";

export default function FormCalendar({
  name,
  label,
  placeholder,
  py,
  loading,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box
      component={Paper}
      borderRadius="10px"
      overflow="hidden"
      my={py}
      elevation={3}
      className={classes.root}
    >
      {loading ? (
        <InputLoading />
      ) : (
        <Field
          component={DatePicker}
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          name={name}
          label={label}
          {...restProps}
        />
      )}
    </Box>
  );
}

FormCalendar.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 1,
};
