import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPickersDay-daySelected": {
      color: "#fff",
    },
    "& .MuiPickersDay-day": {
      fontWeight: "bolder",
    },

    "& .MuiPickersToolbarText-toolbarTxt": {
      color: "#fff",
    },
    "& .MuiPickersBasePicker-pickerView": {
      width: "100%",
    },
    "& .MuiPickersToolbar-toolbarLandscape": {
      maxWidth: "none",
    },
    // "& .MuiFormControl-root": {
    //   "& .MuiOutlinedInput-input": {
    //     paddingTop: "16px",
    //     paddingBottom: "16px",
    //     textTransform: "inherit",
    //   },
    //   "&:hover .MuiOutlinedInput-notchedOutline": {
    //     borderColor: theme.palette.primary.main,
    //   },
    //   "&:hover .MuiFormLabel-root": {
    //     color: theme.palette.primary.main + "!important",
    //   },
    //   "& .MuiInputBase-root": {
    //     borderRadius: "8px",
    //     "&:hover": {
    //       color: theme.palette.primary.main,
    //     },
    //   },
    // },
  },
}));

export default useStyles;
