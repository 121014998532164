import { customerApiUrls } from "../../CONSTANTS/ApiUrl";
import { getRequest, postRequest } from "../http";

export const getAllCustomers = () => {
  return getRequest({
    url: `${customerApiUrls.getAll}`,
  });
};

export const addCustomer = (values) => {
  return postRequest({
    url: `${customerApiUrls.add}`,
    data: values,
  });
};

export const editCustomer = (values) => {
  return postRequest({
    url: `${customerApiUrls.edit}`,
    data: values,
  });
};