import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";
import { getAllBanks } from "../ADAPTERS/banks";
import { findIndex, pullAt } from "lodash";
const cleanDeep = require("clean-deep");

var _ = require("lodash");

export const immer = (config) => (set, get) =>
  config((fn) => set(produce(fn)), get);

export const useBankListStore = create(
  devtools(
    persist(
      (set) => ({
        data: [],
        loading: false,
        getData: () => {
          set({ loading: true });
          getAllBanks()
            .then((res) => {
              set({ data: res.data });
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => set({ loading: false }));
        },
      }),
      { name: "Banks List" }
    )
  )
);

export const useBankSavedDataStore = create(
  devtools(
    persist(
      (set) => ({
        data: [],
        saveData: (data) =>
          set((state) => ({ data: cleanDeep([...state.data, data]) })),
        remove: (code) =>
          set((state) => {
            let tempData = [...state.data];
            pullAt(tempData, findIndex(tempData, { code: code }));
            return { data: cleanDeep(tempData) };
          }),
        reset: (data) => set((state) => ({ data: [] })),
      }),
      { name: "Bank Saved Data" }
    )
  )
);

export const useAddBankStore = create(
  devtools((set) => ({
    isOpen: false,
    actions: [],
    setActions: (actions) => set((state) => ({ actions: actions })),
    open: () => set((state) => ({ isOpen: true })),
    close: () => set((state) => ({ isOpen: false })),
  }))
);
