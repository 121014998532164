import React from "react";
import { Box } from "@material-ui/core";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import useStyles from "./style";
import InputLoading from "../InputLoading";

export default function FormInput({
  name,
  label,
  placeholder,
  py,
  loading,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box py={py} className={classes.root}>
      {loading ? (
        <InputLoading />
      ) : (
        <FastField
          name={name}
          label={label}
          component={TextField}
          variant="outlined"
          fullWidth={true}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          {...restProps}
        />
      )}
    </Box>
  );
}

FormInput.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 1,
};
