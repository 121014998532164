import { Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React from "react";

export default function TheButton(props) {
  const {
    disabled,
    loading,
    label,
    onClick,
    color,
    type,
    large,
    customColor,
    ...restProps
  } = props;

  const useStyles = makeStyles((theme) => ({
    theButton: {
      borderRadius: "8px",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "21px",
      textAlign: "center",
      padding: !large && theme.spacing(1, 3),
      borderColor: customColor,
      color: customColor,
    },
  }));

  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type={type || "button"}
      variant="outlined"
      color={color}
      className={classes.theButton}
      size={large && "large"}
      {...restProps}
    >
      <Grid container>
        {loading ? (
          <CircularProgress color={color || "primary"} size={20} />
        ) : (
          label
        )}
      </Grid>
    </Button>
  );
}
