import { Avatar, makeStyles } from "@material-ui/core";
import React from "react";
import FormAutocomplete from "../../../SHARED/FormComponents/FormAutocomplete";
import { currencyList } from "../../../../CONSTANTS";
import { FlagOutlined } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(4),
    width: theme.spacing(6),
    marginRight: theme.spacing(1),
    border: "1px solid #cdc",
  },
}));

export default function CurrencyAutocomplete({ value, ...props }) {
  const classes = useStyles();
  return (
    <div>
      <FormAutocomplete
        {...props}
        required
        options={currencyList}
        inputProps={{
          startAdornment: value && (
            <Avatar
              variant="square"
              src={`/flags/${value.value}.png`}
              className={classes.avatar}
            >
              <FlagOutlined />
            </Avatar>
          ),
        }}
      />
    </div>
  );
}
