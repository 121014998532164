import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.primary.main,
      },
      "&:hover .MuiFormLabel-root": {
        color: theme.palette.primary.main + "!important",
      },
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        "& .MuiSelect-root": {
          paddingTop: "16px",
          paddingBottom: "16px",
          paddingRight: "40px!important",
        },
        "&:hover": {
          color: theme.palette.primary.main,
        },
      },
    },
  },
  dropArrow: {
    height: "100%",
    top: "0",
    display: "flex",
    alignItems: "center",
    padding: "0 0.6em",
    transitionDuration: ".3s",
  },
}));

export default useStyles;
