import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";
import { getAllCustomers } from "../ADAPTERS/customer";

export const immer = (config) => (set, get) =>
  config((fn) => set(produce(fn)), get);

export const useCustomerListStore = create(
  devtools(
    persist(
      (set) => ({
        data: [],
        loading: false,
        getData: () => {
          set({ loading: true });
          getAllCustomers()
            .then((res) => {
              set({ data: res.data });
            })
            .catch((err) => {
              console.log(err.message);
            })
            .finally(() => set({ loading: false }));
        },
      }),
      { name: "Customers List" }
    )
  )
);

export const useAddCustomerStore = create(
  devtools((set) => ({
    isOpen: false,
    customerId: false,
    actions: [],
    setActions: (actions) => set((state) => ({ actions: actions })),
    open: (custId) => set((state) => ({ isOpen: true, customerId: custId })),
    close: () =>
      set((state) => ({ isOpen: false, actions: [], customerId: false })),
  }))
);
