import { Box, IconButton, Tooltip } from "@material-ui/core";
import { FullscreenExitRounded, FullscreenRounded } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import React, { Fragment } from "react";
import useStyles from "./style";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTheme } from "@material-ui/styles";

export default function OilTank({ data, columns, options, title }) {
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const theme = useTheme();

  return (
    <FullScreen handle={handle}>
      <Box
        borderRadius={10}
        overflow={"hidden"}
        className={`${classes.oiltank} border`}
      >
        <MUIDataTable
          data={data}
          columns={columns}
          options={{
            ...options,
            selection: false,
            selectableRows: "none",
            selectableRowsHeader: false,
            responsive: "standard",
            fixedHeader: true,
            tableBodyHeight: handle.active
              ? options.pagination === false
                ? "91vh"
                : "84vh"
              : "100%",
            paging: false,
            viewColumns: !handle.active,
            filter: !handle.active,
            rowsPerPageOptions: handle.active ? false : [10, 20, 50, 100, 300],
            setTableProps: () => {
              let prevProps = options.setTableProps
                ? options.setTableProps()
                : {};
              return {
                ...prevProps,
                style: {
                  marginTop: !handle.active && theme.spacing(1),
                },
              };
            },
            customToolbar: () => {
              return (
                <Fragment>
                  <Tooltip title="Full Screen">
                    {handle.active ? (
                      <IconButton onClick={handle.exit}>
                        <FullscreenExitRounded />
                      </IconButton>
                    ) : (
                      <IconButton onClick={handle.enter}>
                        <FullscreenRounded />
                      </IconButton>
                    )}
                  </Tooltip>
                  {options.customToolbar && options.customToolbar()}
                </Fragment>
              );
            },
          }}
          title={title}
        />
      </Box>
    </FullScreen>
  );
}
