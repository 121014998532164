import React from "react";
import Page from "../../components/SHARED/Page";

export default function Payments() {
  return (
    <Page title="MHCO | Payments" p={4}>
      Payments
    </Page>
  );
}
