import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { Fragment, useEffect } from "react";
import { useCustomerListStore } from "../../../../STORE/useCustomerStore";
import FormAutocomplete from "../../../SHARED/FormComponents/FormAutocomplete";
import { Icon } from "@iconify/react";

import loadingflowccwIcon from "@iconify/icons-whh/loadingflowccw";
import { getImage } from "../../../../CONSTANTS/Minions";

export default function CustomerAutocomplete(props) {
  const { data, getData, loading } = useCustomerListStore();

  useEffect(() => {
    isEmpty(data) && getData();
  }, []);

  return (
    <div>
      <FormAutocomplete
        {...props}
        required
        options={
          data
            ? data.map((item, index) => {
                return {
                  label: `${item.name}`,
                  value: `${item.customerId}`,
                  ...item,
                };
              })
            : []
        }
        renderOption={(option) => {
          return (
            <ListItem className="p-0" role='contentinfo'>
              <ListItemAvatar>
                <Avatar alt={option.name} src={option.imgUrl} />
              </ListItemAvatar>
              <ListItemText
                primary={option.name}
                secondary={
                  <Fragment>
                    <Typography component="b" variant="body2">
                      {option.value}
                    </Typography>
                  </Fragment>
                }
              />
            </ListItem>
          );
        }}
        inputProps={{
          endAdornment: (
            <div className="MuiAutocomplete-endAdornment">
              <Box pr={1}>
                <IconButton
                  tabIndex={-1}
                  className="MuiAutocomplete-popupIndicator"
                  onClick={() => getData()}
                >
                  {loading ? (
                    <CircularProgress size="25px" thickness={4} />
                  ) : (
                    <Icon icon={loadingflowccwIcon} />
                  )}
                </IconButton>
              </Box>
            </div>
          ),
        }}
      />
    </div>
  );
}
