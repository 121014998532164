import { invoiceApiUrls } from "../../CONSTANTS/ApiUrl";
import { postRequest } from "../http";

export const uploadSingleInvoice = (values) => {
  return postRequest({
    url: `${invoiceApiUrls.upload}`,
    data: [values],
  });
};

export const uploadMultipleInvoices = (values) => {
  return postRequest({
    url: `${invoiceApiUrls.upload}`,
    data: values,
  });
};
