import { FileCopyOutlined, ReplayOutlined } from "@material-ui/icons";
import { Formik } from "formik";
import { get } from "lodash";
import React, { useContext } from "react";
import BankAutocomplete from "./BankAutocomplete";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";
import TheButton from "../../SHARED/Buttons/TheButton";
import { useBankSavedDataStore } from "../../../STORE/useBankStore";
import { uploadSingleTransaction } from "../../../ADAPTERS/banks";
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import * as Yup from "yup";
import {
  CurrencyAutocomplete,
  FormCalendar,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormSelect,
  FormToggle,
} from "../../SHARED/FormComponents";

const { nanoid } = require("nanoid");

export default function Form() {
  const theme = useTheme();
  const { notify } = useContext(AppContext);
  const { saveData } = useBankSavedDataStore();

  return (
    <div>
      <Box p={3}>
        <Formik
          // data[0] ||
          initialValues={{
            bank: null,
            currency: null,
            date: new Date(),
            transactionType: "credit",
            payeeBank: "",
            memo: "",
            payee: "",
            code: nanoid(10),
            chequeOtherNo: "",
            mode: "",
            note: ["", ""],
            amount: "",
            submitType: "upload",
          }}
          validationSchema={Yup.object({
            bank: Yup.object()
              .required("Bank Name Required")
              .typeError("Invalid Bank"),
            currency: Yup.object()
              .required("Currency Name Required")
              .typeError("Invalid Currency"),
            payeeBank: Yup.string().required("Payee Bank Name Required"),
            mode: Yup.string().required("Payment Mode Required"),
            payee: Yup.string().required("Payee Name Required"),
            amount: Yup.number().required("Amount Required"),
            chequeOtherNo: Yup.string().when("mode", {
              is: (val) => val !== "Other",
              then: Yup.string().required("Cheque/Other No Required"),
            }),
          })}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            if (values.submitType === "save") {
              saveData(values);
              notify("Data saved successfully", "success");
              setSubmitting(false);
            } else {
              uploadSingleTransaction(values)
                .then((res) => {
                  notify(res.message, "success");
                })
                .catch((err) => {
                  notify(err.message, "error");
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
            setFieldValue("code", nanoid(10));
          }}
        >
          {({
            values,
            handleSubmit,
            errors,
            touched,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3} alignItems='center'>
                  <Grid item md={6} xs={12}>
                    <BankAutocomplete
                      name="bank"
                      label="Bank"
                      placeholder="Select Bank"
                      errors={errors}
                      touched={touched}
                      py={0.5}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <CurrencyAutocomplete
                      name="currency"
                      label="Currency"
                      placeholder="Select Currency"
                      errors={errors}
                      touched={touched}
                      value={values.currency}
                      py={0.5}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormInput
                      name="payeeBank"
                      label="Payee's Bank"
                      placeholder="Enter payee's bank name"
                      py={0.5}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormInput
                      name="payee"
                      label="Payee Name"
                      placeholder="Enter payee name"
                      py={0.5}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Hidden mdDown>
                      <FormCalendar name="date" label="Date" py={0.5} />
                    </Hidden>
                    <Hidden lgUp>
                      <FormDatePicker name="date" label="Date" py={0.5} />
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <FormNumberInput
                          py={0.5}
                          name="amount"
                          label="Amount"
                          placeholder="Enter Amount"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {get(values, "currency.value")}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormToggle
                          name="transactionType"
                          label="Transaction Type"
                          options={[
                            {
                              label: "Credit",
                              value: "credit",
                              color: theme.palette.success.main,
                            },
                            {
                              label: "Debit",
                              value: "debit",
                              color: theme.palette.secondary.main,
                            },
                          ]}
                          placeholder="Select Transaction Type"
                          value={values.transactionType}
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormInput
                          name="chequeOtherNo"
                          label="Cheque / Other No"
                          placeholder="Enter cheque/other no"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormSelect
                          name="mode"
                          label="Payment Mode"
                          options={[
                            { label: "Cash", value: "Cash" },
                            { label: "Cheque", value: "Cheque" },
                            { label: "ATM Online", value: "ATM Online" },
                            { label: "Pay Order", value: "Pay Order" },
                            { label: "Other", value: "Other" },
                          ]}
                          placeholder="Select Payment Mode"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormInput
                          name="note[0]"
                          label="Note 1"
                          placeholder="Enter note 1"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormInput
                          name="note[1]"
                          label="Note 2"
                          placeholder="Enter note 1"
                          py={0.5}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <FormInput
                      name="memo"
                      label="Memo"
                      placeholder="Enter memo"
                      py={0.5}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormInput
                      name="code"
                      label="Code"
                      py={0.5}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Copy">
                              <CopyToClipboard
                                text={values.code}
                                onCopy={() =>
                                  notify("Code copied successfully", "success")
                                }
                              >
                                <IconButton edge="end" size="small">
                                  <FileCopyOutlined />
                                </IconButton>
                              </CopyToClipboard>
                            </Tooltip>
                            &nbsp;&nbsp;
                            <Tooltip title="Reload">
                              <IconButton
                                size="small"
                                edge="end"
                                onClick={() =>
                                  setFieldValue("code", nanoid(10))
                                }
                              >
                                <ReplayOutlined />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box mt={3} mb={1}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <TheButton
                        large
                        label="Reset"
                        customColor={theme.palette.secondary.main}
                        onClick={resetForm}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item>
                      <TheButton
                        large
                        label="Save"
                        customColor={theme.palette.success.main}
                        onClick={() => {
                          setFieldValue("submitType", "save");
                          handleSubmit();
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item>
                      <TheButton
                        large
                        label="Upload"
                        color="primary"
                        onClick={() => {
                          setFieldValue("submitType", "upload");
                          handleSubmit();
                        }}
                        loading={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </div>
  );
}
