import {
  bankClientUrls,
  invoiceClientUrls,
  paymentsClientUrls,
} from "../../../CONSTANTS/ClientUrls";
import billIcon from "@iconify/icons-icon-park/bill";
import dollarBanknote from "@iconify/icons-noto/dollar-banknote";
import bankIcon from "@iconify/icons-noto/bank";

const SidebarConfig = [
  {
    label: "Bank",
    path: bankClientUrls.base,
    icon: bankIcon,
  },
  {
    label: "Invoice",
    path: invoiceClientUrls.base,
    icon: billIcon,
  },
  {
    label: "Payment",
    path: paymentsClientUrls.base,
    icon: dollarBanknote,
  },
];

export default SidebarConfig;
