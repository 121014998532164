import { Fragment } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import TheLayout from './components/Layout/TheLayout';
import { bankClientUrls, invoiceClientUrls, paymentsClientUrls, protectedUrl } from './CONSTANTS/ClientUrls';
import Bank from './PAGES/Bank'
import Invoice from './PAGES/Invoice';
import Payments from './PAGES/Payments';

export default function Router() {
  return <Fragment>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to={protectedUrl} />; // ! redirect to /app
        }}
      />

      {/* <Route exact path={["/login", "/register", "/"]}>
        <AuthLayout>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
        </AuthLayout>
      </Route> */}

      <Route path={protectedUrl}>
        <TheLayout>
          <ProtectedRoutes />
        </TheLayout>
      </Route>

    </Switch>
  </Fragment>
}

const ProtectedRoutes = () => {
  let { path } = useRouteMatch();

  return <Switch>
    <Route
      exact
      path={path}
      render={() => {
        return <Redirect to={bankClientUrls.base} />;
      }}
    />

    <Route path={bankClientUrls.base}>
      <Bank />
    </Route>

    <Route path={invoiceClientUrls.base}>
      <Invoice />
    </Route>

    <Route path={paymentsClientUrls.base}>
      <Payments />
    </Route>
  </Switch>
}