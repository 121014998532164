import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import { IconButton, ThemeProvider } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import App from './App';
import './bootstrap.css'
import muiTheme from './Theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BrowserRouter>
            <SnackbarProvider
              hideIconVariant={false}
              maxSnack={3}
              anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              ref={notistackRef}
              style={{ borderRadius: "8px" }}
              action={(key) => (
                <IconButton onClick={onClickDismiss(key)} size="small">
                  <Close htmlColor="#fff" />
                </IconButton>
              )}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
