export const colors = {
  labelColor: "#989898",
  primary: "#3BAFDA",
  infoColor: "#808080",
  black1: "#1a181e",
  black2: "#4d4d4d",
  black3: "#707E88",
  purple1: "#3bafda",
  purple2: "#3bafda",
  purple3: "#9F7AEA",
  white3: "#d9d9d9",
  white7: "#f5f5f5",
  borderColor: "#e6e6e6",
};
 