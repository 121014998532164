import React from "react";
import { ListItemText } from "@material-ui/core";
import useStyles from "./style";
import { Skeleton } from "@material-ui/lab";

export default function TableListItemText({
  primary,
  secondary,
  loading,
  ...restProps
}) {
  const classes = useStyles();

  return (
    <ListItemText
      primary={loading ? <Skeleton /> : primary}
      secondary={loading ? <Skeleton /> : secondary}
      {...restProps}
      className={classes.listItemText}
    />
  );
}

TableListItemText.defaultProps = {
  primary: "",
  secondary: "",
  loading: "",
};
