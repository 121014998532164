// import { createMuiTheme } from "@material-ui/core";
import { colors } from "./colors";
import { createTheme } from "@material-ui/core/styles";

const muiTheme = createTheme({
  typography: {
    fontFamily: '"Inter", san-serif',
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    purple: colors.purple3,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },

    MuiButton: {
      sizeLarge: {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
    //! important global styles for input fields and their legends
    MuiFormLabel: {
      asterisk: {
        color: "red",
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "14px",
      },
      shrink: {
        fontSize: "14px",
        transform: "translate(14px, -6px) scale(0.9)!important",
        background: "#fff",
        paddingRight: "5px",
      },
    },
    MuiInputBase: {
      input: {
        "&:focus": {
          color: colors.primary,
        },
        fontSize: "16px",
      },
    },
    MuiFormControl: {
      root: {
        width: "100%",
      },
    },
    MuiSelect: {
      root: {
        "&:focus": {
          background: "none!important",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.infoColor,
        fontSize: "12px",
        lineHeight: "16px",
      },
    },
    MuiTabs: {
      root: {
        borderBottom: `solid 1px ${colors.borderColor}`,
      },
    },
    MuiTab: {
      root: {
        fontSize: "16px",
        fontWeight: "500",
        color: colors.black2,
        opacity: 1,
        // marginRight: "24px",
        textTransform: "none",

        "&.Mui-selected": {
          color: colors.primary,
        },
        "&:hover": {
          color: colors.primary,
        },
        "&:focus": {
          color: colors.primary,
        },
        "&:active": {
          color: colors.primary,
        },
      },
      textColorInherit: {
        color: colors.black2,
        opacity: 1,
      },
    },
  },
});

export default muiTheme;
