import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import MaterialDialog from "../SHARED/MaterialDialog";
import {
  useAddCustomerStore,
  useCustomerListStore,
} from "../../STORE/useCustomerStore";
import * as Yup from "yup";
import { addCustomer, editCustomer } from "../../ADAPTERS/customer";
import AppContext from "../../CONTEXTS/AppContext/AppContext";
import { Grid } from "@material-ui/core";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import {
  FormInput,
  FormNumberInput,
  FormTextArea,
} from "../SHARED/FormComponents";
import { find } from "lodash";
import { nanoid } from "nanoid";
import { IMAGE_UPLOAD_API_URL } from "../../CONSTANTS/ApiUrl";

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview
  // FilePondPluginFileEncode
);

export default function AddDialog() {
  const { isOpen, close, actions, customerId } = useAddCustomerStore();
  const { notify } = useContext(AppContext);
  const { getData } = useCustomerListStore();
  const customers = useCustomerListStore((state) => state.data);
  const [initVals, setInitVals] = useState({});

  // console.log(find(customers, { customerId: customerId }));

  useEffect(() => {
    if (customerId) {
      let cust = find(customers, { customerId: customerId });
      setInitVals({
        ...cust,
        image: [
          {
            source: cust.imgUrl,
            options: { type: "local" },
          },
        ],
      });
    }
  }, [customerId]);

  return (
    <div>
      <MaterialDialog
        handleClose={(event, reason) => {
          if (reason !== "backdropClick") {
            close();
          }
        }}
        open={isOpen}
        title={customerId ? "Edit customer" : "Add New Customer"}
        size="sm"
        content={
          <Formik
            initialValues={
              customerId
                ? initVals
                : {
                    name: "",
                    mobile: "",
                    details: "",
                    image: "",
                    customerId: nanoid(10),
                  }
            }
            // validationSchema={Yup.object({
            //   mobile: Yup.string().required("Customer mobile number required"),
            //   name: Yup.string().required("Customer name required"),
            //   details: Yup.string().required("Customer details required"),
            // })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log(values);
              if (values.row) {
                editCustomer(values)
                  .then((res) => {
                    console.log(res);
                    notify(res.message, res.status);
                    if (res.status === "success") {
                      getData();
                      resetForm();
                      close();
                    }
                  })
                  .catch((err) => {
                    notify(err.message, "error");
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              } else {
                addCustomer(values)
                  .then((res) => {
                    notify(res.message, res.status);
                    if (res.status === "success") {
                      getData();
                      resetForm();
                      close();
                    }
                  })
                  .catch((err) => {
                    notify(err.message, "error");
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }
            }}
            component={Form}
          />
        }
        actions={actions}
      />
    </div>
  );
}

const Form = ({ values, handleSubmit, isSubmitting, setFieldValue }) => {
  const { setActions, close } = useAddCustomerStore();

  useEffect(() => {
    setActions([
      {
        label: "Close",
        disabled: isSubmitting,
        onClick: close,
      },
      {
        label: "Submit",
        disabled: isSubmitting,
        loading: isSubmitting,
        onClick: handleSubmit,
        color: "primary",
      },
    ]);
  }, [isSubmitting]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item md={4} xs={5}>
          <FilePond
            server={{
              fetch: null,
              revert: null,
              restore: null,
              load: (source, load, error, progress, abort, headers) => {
                var myRequest = new Request(source);
                fetch(myRequest).then(function (response) {
                  response.blob().then(function (myBlob) {
                    load(myBlob);
                  });
                });
              },
              process: (
                fieldName,
                file,
                metadata,
                load,
                error,
                progress,
                abort,
                transfer,
                options
              ) => {
                // console.log("uploading", file);
                const formData = new FormData();
                formData.append("image", file);
                const request = new XMLHttpRequest();
                request.open("POST", IMAGE_UPLOAD_API_URL);

                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                };

                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    load(JSON.parse(request.response).data.url);
                    setFieldValue(
                      "image[0].source",
                      JSON.parse(request.response).data.url
                    );
                    // console.log(JSON.parse(request.response).data.url);
                  } else {
                    error("oh no");
                  }
                };
                request.send(formData);
                return {
                  abort: () => {
                    request.abort();
                    abort();
                  },
                };
              },
            }}
            files={values.image}
            // files={[
            //   {
            //     source:
            //       "https://www.pixsy.com/wp-content/uploads/2021/04/ben-sweet-2LowviVHZ-E-unsplash-1.jpeg",
            //     options: {
            //       type: "local",
            //     },
            //   },
            // ]}
            // [
            //   {
            //     source:
            //       "https://www.pixsy.com/wp-content/uploads/2021/04/ben-sweet-2LowviVHZ-E-unsplash-1.jpeg",
            //     options: {
            //       type: "local",
            //     },
            //   },
            // ]
            disabled={isSubmitting}
            className="shadow shadow-sm border border-2"
            acceptedFileTypes={["image/*"]}
            allowMultiple={false}
            allowFileEncode={true}
            stylePanelLayout="integrated circle"
            styleProgressIndicatorPosition="center bottom"
            styleLoadIndicatorPosition="center bottom"
            styleButtonRemoveItemPosition="center bottom"
            styleButtonProcessItemPosition="center bottom"
          />
        </Grid>

        <Grid item md={8} xs={12}>
          <FormInput
            name="name"
            label="Customer Name"
            placeholder="Enter customer name"
            py={2}
          />
          <FormNumberInput
            name="mobile"
            label="Customer Mobile"
            placeholder="Enter customer mobile"
            format="### ### ####"
            py={2}
          />
        </Grid>
      </Grid>

      <FormTextArea
        name="details"
        label="Details"
        placeholder="Enter customer detials"
        py={3}
      />
    </form>
  );
};
