import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import produce from "immer";
import { findIndex, pullAt } from "lodash";
const cleanDeep = require("clean-deep");

export const immer = (config) => (set, get) =>
  config((fn) => set(produce(fn)), get);

export const useInvoiceSavedDataStore = create(
  devtools(
    persist(
      (set) => ({
        data: [],
        saveData: (data) =>
          set((state) => ({ data: cleanDeep([...state.data, data]) })),
        remove: (code) =>
          set((state) => {
            let tempData = [...state.data];
            pullAt(tempData, findIndex(tempData, { code: code }));
            return { data: cleanDeep(tempData) };
          }),
        reset: (data) => set((state) => ({ data: [] })),
      }),
      { name: "Invoice Saved Data" }
    )
  )
);