import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      borderRadius: "10px",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  actions: {
    padding: theme.spacing(3, 4),
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(3),
    },
  },
}));

export default useStyles;
