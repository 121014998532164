import { bankApiUrls } from "../../CONSTANTS/ApiUrl";
import { getRequest, postRequest } from "../http";

export const getAllBanks = () => {
  return getRequest({
    url: `${bankApiUrls.getAllBanks}`,
  });
};

export const uploadSingleTransaction = (values) => {
  return postRequest({
    url: `${bankApiUrls.uploadBankTransaction}`,
    data: [values],
  });
};

export const uploadTransactions = (values) => {
  return postRequest({
    url: `${bankApiUrls.uploadBankTransaction}`,
    data: values,
  });
};

export const getBankTransactions = () => {
  return getRequest({
    url: `${bankApiUrls.getTransactions}`,
  });
};

export const addBank = (values) => {
  return postRequest({
    url: `${bankApiUrls.addBank}`,
    data: values,
  });
};
