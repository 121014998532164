import { Box, CircularProgress, IconButton } from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { useBankListStore } from "../../../../STORE/useBankStore";
import FormAutocomplete from "../../../SHARED/FormComponents/FormAutocomplete";
import { Icon } from "@iconify/react";

import loadingflowccwIcon from "@iconify/icons-whh/loadingflowccw";

export default function BankAutocomplete(props) {
  const { data, getData, loading } = useBankListStore();

  useEffect(() => {
    isEmpty(data) && getData();
  }, []);

  return (
    <div>
      <FormAutocomplete
        {...props}
        required
        options={
          data
            ? data.map((item, index) => {
                return {
                  label: `${item.code} || ${item.bankName}`,
                  value: item.code,
                };
              })
            : []
        }
        inputProps={{
          endAdornment: (
            <div className="MuiAutocomplete-endAdornment">
              <Box pr={1}>
                <IconButton
                  tabIndex={-1}
                  className="MuiAutocomplete-popupIndicator"
                  onClick={() => getData()}
                >
                  {loading ? (
                    <CircularProgress size="25px" thickness={4} />
                  ) : (
                    <Icon icon={loadingflowccwIcon} />
                  )}
                </IconButton>
              </Box>
            </div>
          ),
        }}
      />
    </div>
  );
}
