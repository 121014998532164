import React from "react";
import { Box, IconButton, InputAdornment } from "@material-ui/core";
import { Field } from "formik";
import useStyles from "./style";
import InputLoading from "../InputLoading";
import { DatePicker } from "formik-material-ui-pickers";
import { Event } from "@material-ui/icons";

export default function FormDatePicker({
  name,
  label,
  placeholder,
  py,
  loading,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box py={py} className={classes.root}>
      {loading ? (
        <InputLoading />
      ) : (
        <Field
          component={DatePicker}
          name={name}
          label={label}
          inputVariant="outlined"
          fullWidth={true}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          format="dd MMM yyyy"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" tabIndex={-1}>
                  <Event />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...restProps}
        />
      )}
    </Box>
  );
}

FormDatePicker.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 1,
};
