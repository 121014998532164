import { Fragment } from 'react';
import ScrollToTop from './components/SHARED/ScrollToTop'
import AppProvider from './CONTEXTS/AppContext/AppProvider';
import Router from './routes'

function App() {
  return (
    <Fragment>
      <AppProvider>
        <ScrollToTop />
        <Router />
      </AppProvider>
    </Fragment>
  );
}

export default App;
