import {
  Box,
  Grid,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { isEmpty, sum } from "lodash";
import React, { useEffect, useState } from "react";
import { useAddBankStore, useBankListStore } from "../../../STORE/useBankStore";
import OilTank from "../../SHARED/OilTank";
import Currency from "react-currency-formatter";
import { Cached } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import TheButton from "../../SHARED/Buttons/TheButton";
import AddDialog from "./AddDialog";

var convertRupeesIntoWords = require("convert-rupees-into-words");

export default function BankOil() {
  const { data, getData, loading } = useBankListStore();
  const [totalAmount, setTotalAmount] = useState(0);
  const { open } = useAddBankStore();

  useEffect(() => {
    isEmpty(data) && getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      let ttl = sum(data.map((item) => item.balance));
      setTotalAmount(typeof ttl !== "number" ? 0 : ttl);
    }
  }, [data]);

  return (
    <Box m={3}>
      <Box mb={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ListItemText
              primary={
                loading ? (
                  <Skeleton>
                    <Typography variant="h6">Loading.......</Typography>
                  </Skeleton>
                ) : (
                  <Typography variant="h6">
                    <Currency
                      quantity={sum(data.map((item) => item.balance))}
                      currency="PKR"
                    />
                  </Typography>
                )
              }
              secondary={
                loading ? (
                  <Skeleton width="50ch" />
                ) : (
                  convertRupeesIntoWords(totalAmount)
                )
              }
            />
          </Grid>

          <Grid item>
            <TheButton
              label="Add New Bank"
              color="primary"
              large
              onClick={open}
            />
          </Grid>
        </Grid>
      </Box>

      <OilTank
        title="All Banks"
        data={
          loading
            ? [...new Array(5)].map((item) => [
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
              ])
            : data.map((item) => [
                item.bankName,
                item.type,
                item.code,
                item.balance,
                item.balance && convertRupeesIntoWords(item.balance),
              ])
        }
        columns={[
          "Name",
          "Type",
          "Code",
          {
            name: "Balance",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return loading ? (
                  <Skeleton />
                ) : (
                  <Currency quantity={value} currency="PKR" />
                );
              },
            },
          },
          "In Words",
        ]}
        options={{
          pagination: false,
          customToolbar: () => {
            return (
              <Tooltip title="Reload Data">
                <IconButton onClick={getData} disabled={loading}>
                  <Cached />
                </IconButton>
              </Tooltip>
            );
          },
        }}
      />

      <AddDialog />
    </Box>
  );
}
