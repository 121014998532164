import { invoiceClientUrls } from "../../CONSTANTS/ClientUrls";
import Form from "../../components/Invoice/Form";
import Saved from "../../components/Invoice/Saved";
import Customers from "../../components/Customers";
import Transactions from "../../components/Invoice/Transactions";

export const InvoiceUrls = [
  {
    label: "Form",
    path: invoiceClientUrls.form,
    component: Form,
  },
  {
    label: "Saved",
    path: invoiceClientUrls.saved,
    component: Saved,
  },
  {
    label: "All Customers",
    path: invoiceClientUrls.allCustomers,
    component: Customers,
  },
  {
    label: "Transactions",
    path: invoiceClientUrls.transactions,
    component: Transactions,
  },
];
