import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import { Box, makeStyles, Paper } from "@material-ui/core";

const scrollBarBackgroundColor = "#fff";
const scrollBarColor = "#ccc";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": { width: "14px" },
    "&::-webkit-scrollbar-track": {
      background: scrollBarBackgroundColor,
      borderRadius: "20px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: scrollBarColor,
      borderRadius: "100px",
      border: "3px solid " + scrollBarBackgroundColor,
    },
  },
}));

const Page = forwardRef(({ children, title = "", ...other }, ref) => {
  const classes = useStyles();

  return (
    <Box
      component={Paper}
      borderRadius={10}
      ref={ref}
      {...other}
      overflow="auto"
      height="100%"
      className={classes.root}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
