const deploymentId =
  "AKfycbwlF6ge3QQhup_VVw9_OYfFiDf7wl91KwqU87IVjlohKR3-lCq2LU3G_ODeDix7h-Kv";

const IMAGE_UPLOAD_API_KEY = "382c45a96c3c85d55635b08aa19fb7d7";

export const IMAGE_UPLOAD_API_URL =
  "https://api.imgbb.com/1/upload?key=" + IMAGE_UPLOAD_API_KEY;

const baseUrl = `https://script.google.com/macros/s/${deploymentId}/exec`;

// const baseUrl = "/.netlify/functions/token-hider";

export const bankApiUrls = {
  getAllBanks: `${baseUrl}?action=banks`,
  getTransactions: `${baseUrl}?action=bankTransactions`,
  uploadBankTransaction: `${baseUrl}?action=bankTransaction`,
  addBank: `${baseUrl}?action=addBank`,
};

export const customerApiUrls = {
  getAll: `${baseUrl}?action=customers`,
  add: `${baseUrl}?action=addCustomer`,
  edit: `${baseUrl}?action=editCustomer`,
};

export const invoiceApiUrls = {
  upload: `${baseUrl}?action=invoice`,
};
