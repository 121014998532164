import { Formik } from "formik";
import React, { useContext, useEffect } from "react";
import MaterialDialog from "../../SHARED/MaterialDialog";
import FormInput from "../../SHARED/FormComponents/FormInput";
import { useAddBankStore, useBankListStore } from "../../../STORE/useBankStore";
import * as Yup from "yup";
import { addBank } from "../../../ADAPTERS/banks";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";

export default function AddDialog() {
  const { isOpen, close, actions } = useAddBankStore();
  const { notify } = useContext(AppContext);
  const { getData } = useBankListStore();

  return (
    <div>
      <MaterialDialog
        handleClose={(event, reason) => {
          if (reason !== "backdropClick") {
            close();
          }
        }}
        open={isOpen}
        title="Add New Bank"
        size="sm"
        content={
          <Formik
            initialValues={{ code: "", name: "", type: "", note: ["", ""] }}
            validationSchema={Yup.object({
              code: Yup.string().required("Bank Code Required"),
              name: Yup.string().required("Bank Name Required"),
              type: Yup.string().required("Bank Type Required"),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              addBank(values)
                .then((res) => {
                  notify(res.message, res.status);
                  if (res.status === "success") {
                    getData();
                    resetForm();
                    close();
                  }
                })
                .catch((err) => {
                  notify(err.message, "error");
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
            component={Form}
          />
        }
        actions={actions}
      />
    </div>
  );
}

const Form = ({ handleSubmit, isSubmitting }) => {
  const { setActions, close } = useAddBankStore();

  useEffect(() => {
    setActions([
      {
        label: "Close",
        disabled: isSubmitting,
        onClick: close,
      },
      {
        label: "Submit",
        disabled: isSubmitting,
        loading: isSubmitting,
        onClick: handleSubmit,
        color: "primary",
      },
    ]);
  }, [isSubmitting]);

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        name="code"
        label="Bank Code"
        placeholder="Enter Bank Code"
        py={2}
      />
      <FormInput
        name="name"
        label="Bank Name"
        placeholder="Enter Bank Name"
        py={2}
      />
      <FormInput
        name="type"
        label="Bank Type"
        placeholder="Enter Bank Type"
        py={2}
      />
      <FormInput
        name="note[0]"
        label="Note 1"
        placeholder="Enter Note 1"
        py={2}
      />
      <FormInput
        name="note[1]"
        label="Note 2"
        placeholder="Enter Note 2"
        py={2}
      />
    </form>
  );
};
