import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  oiltank: {
    position: "relative",
    "& th,& .MuiButton-label": {
      // fontWeight: "bolder",
      fontSize: "15px",
      color: "#fff",
    },
    "& .MuiTableCell-head": {
      backgroundColor: theme.palette.primary.light,
      paddingTop: 0,
      paddingBottom: 0,
    },
    // "& table": {
      // marginTop: theme.spacing(1),
    // },
    "& td": {
      fontWeight: 500,
    },
  },
  listItemText: {
    "& .MuiListItemText-primary": {
      fontSize: "14px",
      fontWeight: 500,
    },
    "& .MuiListItemText-secondary": {
      fontWeight: 500,
    },
  },
}));

export default useStyles;
