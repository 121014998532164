import React from "react";
import { Box, TextField } from "@material-ui/core";
import { Field } from "formik";
import useStyles from "./style";
import { Autocomplete } from "formik-material-ui-lab";

export default function FormAutocomplete({
  name,
  label,
  placeholder,
  py,
  options,
  touched,
  errors,
  inputProps,
  renderOption,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box py={py} className={classes.root}>
      <Field
        name={name}
        component={Autocomplete}
        options={options}
        getOptionLabel={(option) => option.label || ""}
        renderOption={(option) => {
          return renderOption ? renderOption(option) : option.label;
        }}
        getOptionSelected={(option, value) => option.value === value.value}
        renderInput={(params) => (
          <TextField
            {...params}
            error={touched[name] && !!errors[name]}
            helperText={touched[name] && errors[name]}
            label={label}
            fullWidth={true}
            placeholder={placeholder}
            variant="outlined"
            {...restProps}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              ...inputProps,
            }}
          />
        )}
      />
    </Box>
  );
}

FormAutocomplete.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 1,
};
