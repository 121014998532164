import {
  Box,
  Grid,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import OilTank from "../../SHARED/OilTank";
import Currency from "react-currency-formatter";
import { Cached } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { getBankTransactions } from "../../../ADAPTERS/banks";
import { useContext } from "react";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";
import { formatDate1, formatTime } from "../../../UTILS/date";
import TableListItemText from "../../SHARED/OilTank/TableListItemText";

var convertRupeesIntoWords = require("convert-rupees-into-words");

export default function Transactions() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { notify } = useContext(AppContext);
  const [totalAmount, setTotalAmount] = useState(123);

  const getData = () => {
    setLoading(true);
    getBankTransactions()
      .then((res) => {
        setData(res.data);
        let ttl = get(res.data[res.data.length - 1], "amount");
        setTotalAmount(typeof ttl === "string" ? 0 : ttl);
      })
      .catch((err) => {
        notify(err.message, "error");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    isEmpty(data) && getData();
  }, []);

  return (
    <Box m={3}>
      <Box mb={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ListItemText
              primary={
                loading ? (
                  <Skeleton>
                    <Typography variant="h6">Loading.......</Typography>
                  </Skeleton>
                ) : (
                  <Typography variant="h6">
                    <Currency quantity={totalAmount} currency="PKR" />
                  </Typography>
                )
              }
              secondary={
                loading ? (
                  <Skeleton width="50ch" />
                ) : (
                  totalAmount && convertRupeesIntoWords(totalAmount)
                )
              }
            />
          </Grid>
        </Grid>
      </Box>

      <OilTank
        title="All Transactions"
        data={
          loading
            ? [...new Array(5)].map((item) => [
                <Skeleton />,
                "",
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
              ])
            : data.map((item) => [
                item.date,
                item.account,
                item.credit ? "CREDIT" : "DEBIT",
                item.debit || item.credit,
                item.currency,
                item.conversionRate,
                item.creditPkr || item.debitPkr,
              ])
        }
        columns={[
          {
            name: "Date | Time",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <TableListItemText
                    primary={formatDate1(value)}
                    secondary={formatTime(value)}
                    loading={loading}
                  />
                );
              },
            },
          },
          {
            name: "Account",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <TableListItemText
                    primary={value.split("||")[1]}
                    secondary={value.split("||")[0]}
                    loading={loading}
                  />
                );
              },
            },
          },
          {
            name: "Type",
            options: {
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return loading ? (
                  <Skeleton />
                ) : (
                  <Typography
                    variant="subtitle2"
                    className={
                      value === "CREDIT" ? "text-success" : "text-danger"
                    }
                  >
                    {value}
                  </Typography>
                );
              },
            },
          },
          "Amount",
          "Currency",
          "Conversion Rate",
          {
            name: "To PKR",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return loading ? (
                  <Skeleton />
                ) : (
                  <Currency quantity={value} currency="PKR" />
                );
              },
            },
          },
        ]}
        options={{
          customToolbar: () => {
            return (
              <Tooltip title="Reload Data">
                <IconButton onClick={getData} disabled={loading}>
                  <Cached />
                </IconButton>
              </Tooltip>
            );
          },
          setTableProps: () => {
            return {
              size: "small",
            };
          },
        }}
      />
    </Box>
  );
}
