import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { get, isEmpty, sum } from "lodash";
import React, { useEffect, useState, Fragment } from "react";
import {
  useAddCustomerStore,
  useCustomerListStore,
} from "../../STORE/useCustomerStore";
import OilTank from "../SHARED/OilTank";
import Currency from "react-currency-formatter";
import { Cached } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import TheButton from "../SHARED/Buttons/TheButton";
import AddDialog from "./AddEditDialog";
import MoreMenu from "../SHARED/MoreMenu";
import { Icon } from "@iconify/react";
import fullTrash from "@iconify/icons-flat-color-icons/full-trash";
import pencilIcon from "@iconify/icons-twemoji/pencil";

var convertRupeesIntoWords = require("convert-rupees-into-words");

export default function Oil() {
  const { data, getData, loading } = useCustomerListStore();
  const [totalInvoice, setTotalInvoice] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const { open, setCustomerId } = useAddCustomerStore();

  useEffect(() => {
    isEmpty(data) && getData();
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      let ttl = sum(data.map((item) => item.invoiceAmount));
      setTotalInvoice(typeof ttl !== "number" ? 0 : ttl);

      ttl = sum(data.map((item) => item.paymentAmount));
      setTotalPayment(typeof ttl !== "number" ? 0 : ttl);
    }
  }, [data]);

  return (
    <Box m={3}>
      <Box mb={3}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item md={6} xs={12}>
            <ListItemText
              className="border-start border-danger border-5 ps-3"
              primary={
                loading ? (
                  <Skeleton>
                    <Typography variant="h6">Loading.......</Typography>
                  </Skeleton>
                ) : (
                  <Typography variant="h6">
                    <Currency quantity={totalInvoice} currency="PKR" />
                  </Typography>
                )
              }
              secondary={
                loading ? (
                  <Skeleton width="50ch" />
                ) : (
                  convertRupeesIntoWords(totalInvoice)
                )
              }
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <ListItemText
              className="border-start border-success border-5 ps-3"
              primary={
                loading ? (
                  <Skeleton>
                    <Typography variant="h6">Loading.......</Typography>
                  </Skeleton>
                ) : (
                  <Typography variant="h6">
                    <Currency quantity={totalPayment} currency="PKR" />
                  </Typography>
                )
              }
              secondary={
                loading ? (
                  <Skeleton width="50ch" />
                ) : (
                  <Fragment>{convertRupeesIntoWords(totalPayment)}</Fragment>
                )
              }
            />
          </Grid>
        </Grid>

        <Box textAlign="right" mt={2}>
          <TheButton
            label="Add New Customer"
            color="primary"
            large
            onClick={() => open(false)}
          />
        </Box>
      </Box>

      <OilTank
        title="All Customers"
        data={
          loading
            ? [...new Array(5)].map((item) => [
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
                <Skeleton />,
              ])
            : data.map((item) => [
                get(item, "imgUrl"),
                get(item, "name"),
                get(item, "mobile"),
                item.invoiceAmount,
                item.paymentAmount,
                item.details,
                item.customerId,
                item.customerId,
              ])
        }
        columns={[
          {
            name: "Image",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <Avatar variant="rounded" src={value} />;
              },
            },
          },
          "Name",
          "Mobile",
          {
            name: "Invoice Amount",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return loading ? (
                  <Skeleton />
                ) : (
                  <Currency quantity={value} currency="PKR" />
                );
              },
            },
          },
          {
            name: "Payment Amount",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return loading ? (
                  <Skeleton />
                ) : (
                  <Currency quantity={value} currency="PKR" />
                );
              },
            },
          },
          "Details",
          {
            name: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <MoreMenu
                    items={[
                      {
                        action: () => {
                          open(value);
                        },
                        icon: <Icon icon={pencilIcon} fontSize={20} />,
                        label: "Edit",
                      },
                      {
                        action: "() => remove(value)",
                        icon: <Icon icon={fullTrash} fontSize={22} />,
                        label: "Delete",
                      },
                    ]}
                  />
                );
              },
            },
          },
        ]}
        options={{
          pagination: false,
          customToolbar: () => {
            return (
              <Tooltip title="Reload Data">
                <IconButton onClick={getData} disabled={loading}>
                  <Cached />
                </IconButton>
              </Tooltip>
            );
          },
        }}
      />

      <AddDialog />
    </Box>
  );
}
