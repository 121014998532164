import { Box } from "@material-ui/core";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import useStyles from "./style";
import InputLoading from "../InputLoading";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function FormNumberInput({
  label,
  name,
  placeholder,
  py,
  format,
  InputProps,
  loading,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box py={py} className={classes.root}>
      {loading ? (
        <InputLoading />
      ) : (
        <FastField
          name={name}
          label={label}
          component={TextField}
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          className={classes.input}
          format={format}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: { format: format },
            ...InputProps,
          }}
          {...restProps}
        />
      )}
    </Box>
  );
}

FormNumberInput.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 0,
};
