import React from "react";
import { Box, MenuItem } from "@material-ui/core";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import useStyles from "./style";
import nextId from "react-id-generator";
import InputLoading from "../InputLoading";

export default function FormSelect({
  options,
  name,
  label,
  placeholder,
  py,
  loading,
  ...restProps
}) {
  const classes = useStyles();
  const id = nextId();
  return (
    <Box py={py} className={classes.root}>
      {loading ? (
        <InputLoading />
      ) : (
        <FastField
          id={id}
          name={name}
          label={label}
          component={TextField}
          variant="outlined"
          select
          fullWidth={true}
          InputLabelProps={{
            shrink: true,
            id: id,
          }}
          SelectProps={{
            displayEmpty: true,
            IconComponent: (props) => {
              return (
                <section className={`${props.className} ${classes.dropArrow}`}>
                  <svg
                    width={14}
                    height={8}
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1l6 6 6-6"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </section>
              );
            },
            id: id,
            MenuProps: {
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            },
          }}
          {...restProps}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {options &&
            options.map((item, index) => (
              <MenuItem key={index} value={item.value || ""}>
                {item.label}
                {/* item.customLabel ||  */}
              </MenuItem>
            ))}
        </FastField>
      )}
    </Box>
  );
}

FormSelect.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  options: [{}],
  py: 1,
};
