import { Formik } from "formik";
import { nanoid } from "nanoid";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";
import { useInvoiceSavedDataStore } from "../../../STORE/useInvoiceStore";
import {
  Avatar,
  Box,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import * as Yup from "yup";
import {
  CurrencyAutocomplete,
  FormCalendar,
  FormDatePicker,
  FormInput,
  FormNumberInput,
  FormSelect,
} from "../../SHARED/FormComponents";
import { TheButton } from "../../SHARED/Buttons";
import { find, get } from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";
import { FileCopyOutlined, ReplayOutlined } from "@material-ui/icons";
import CustomerAutocomplete from "./CustomerAutocomplete";
import { useCustomerListStore } from "../../../STORE/useCustomerStore";
import { getImage } from "../../../CONSTANTS/Minions";
import { uploadSingleInvoice } from "../../../ADAPTERS/invoice";
const getSymbolFromCurrency = require("currency-symbol-map");

export default function Form() {
  const theme = useTheme();
  const { notify } = useContext(AppContext);
  const { saveData } = useInvoiceSavedDataStore();
  const customersList = useCustomerListStore((state) => state.data);

  return (
    <div>
      <Box p={3}>
        <Formik
          initialValues={{
            customer: null,
            currency: null,
            customerMobile: "",
            customerDetails: "",
            date: new Date(),
            description: "",
            occupancyType: "",
            weight: 0,
            tare: 0,
            totalWeight: 0,
            unitPrice: 0,
            discount: 0,
            amount: 0,
            reference: "",
            note: "",
            code: nanoid(10),
            submitType: "upload",
          }}
          validationSchema={Yup.object({
            customer: Yup.object()
              .required("Customer Name Required")
              .typeError("Invalid Bank"),
            currency: Yup.object()
              .required("Currency Name Required")
              .typeError("Invalid Currency"),
            description: Yup.string().required("Description required"),
            occupancyType: Yup.string().required("Occupancy type required"),
            weight: Yup.number()
              .required("Weight required")
              .moreThan(0, "Weight must be greater than 0"),
            tare: Yup.number().required("Tare required"),
            unitPrice: Yup.number()
              .required("Unit Price required")
              .moreThan(0, "Unit price must be greater than 0"),
            discount: Yup.number().required("Discount required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            if (values.submitType === "save") {
              saveData(values);
              notify("Data saved successfully", "success");
              setSubmitting(false);
            } else {
              uploadSingleInvoice(values)
                .then((res) => {
                  notify(res.message, "success");
                })
                .catch((err) => {
                  notify(err.message, "error");
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }
            setFieldValue("code", nanoid(10));
          }}
        >
          {function ShowForm({
            values,
            handleSubmit,
            errors,
            touched,
            resetForm,
            setFieldValue,
            isSubmitting,
          }) {
            const [currencySymbol, setCurrencySymbol] = useState();

            useEffect(() => {
              if (values.customer) {
                setFieldValue(
                  "customerMobile",
                  get(
                    find(customersList, {
                      customerId: get(values.customer, "value"),
                    }),
                    "mobile"
                  )
                );

                setFieldValue(
                  "customerDetails",
                  get(
                    find(customersList, {
                      customerId: get(values.customer, "value"),
                    }),
                    "details"
                  )
                );
              }
            }, [setFieldValue, values.customer]);

            useEffect(() => {
              if (values.weight || values.tare) {
                setFieldValue("totalWeight", values.weight - values.tare);
              }
            }, [setFieldValue, values.tare, values.weight]);

            useEffect(() => {
              if (values.unitPrice || values.discount) {
                setFieldValue(
                  "amount",
                  values.totalWeight * values.unitPrice - values.discount
                );
              }
            }, [
              setFieldValue,
              values.unitPrice,
              values.discount,
              values.totalWeight,
            ]);

            // useEffect(() => {
            //   if (values.currency) {
            //     setCurrencySymbol(
            //       getSymbolFromCurrency(get(values, "currency.value"))
            //     );
            //   }
            // }, [values.currency]);

            return (
              <form onSubmit={handleSubmit}>
                <Box
                  component={Grid}
                  py={0.5}
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item md={2} xs={12}>
                    <Box
                      p={1}
                      component={Paper}
                      borderRadius={10}
                      className="border"
                    >
                      <Avatar
                        src={getImage(get(values.customer, "image"))}
                        variant="rounded"
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                          aspectRatio: "1/1",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={10} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12}>
                        <CustomerAutocomplete
                          name="customer"
                          label="Customer Name"
                          placeholder="Select customer"
                          errors={errors}
                          touched={touched}
                          py={0.5}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <FormInput
                          name="customerMobile"
                          label="Customer Mobile"
                          placeholder="Select customer to get mobile number"
                          py={0.5}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                  <CopyToClipboard
                                    text={values.customerMobile}
                                    onCopy={() =>
                                      notify(
                                        "Customer Mobile number copied successfully",
                                        "success"
                                      )
                                    }
                                  >
                                    <IconButton edge="end" size="small">
                                      <FileCopyOutlined />
                                    </IconButton>
                                  </CopyToClipboard>
                                </Tooltip>
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormInput
                          name="customerDetails"
                          label="Customer Details"
                          placeholder="Select customer to get customer details"
                          py={0.5}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Hidden mdDown>
                      <FormCalendar name="date" label="Date" py={0.5} />
                    </Hidden>
                    <Hidden lgUp>
                      <FormDatePicker name="date" label="Date" py={0.5} />
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <CurrencyAutocomplete
                          name="currency"
                          label="Currency"
                          placeholder="Select Currency"
                          errors={errors}
                          touched={touched}
                          value={values.currency}
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormInput
                          name="description"
                          label="Description"
                          placeholder="Enter Description"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <FormSelect
                          name="occupancyType"
                          label="Occupancy Type"
                          options={[
                            { label: "Shop", value: "Shop" },
                            { label: "Office", value: "Office" },
                            { label: "Godam", value: "Godam" },
                            { label: "Other", value: "Other" },
                          ]}
                          placeholder="Select Occupancy Type"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="weight"
                          label="Weight"
                          placeholder="Weight"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="tare"
                          label="Tare"
                          placeholder="Tare"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="totalWeight"
                          label="Total Weight"
                          placeholder="Ttl Weight"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="unitPrice"
                          label="Unit Price"
                          placeholder="Unit Price"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="discount"
                          label="Discount"
                          placeholder="Discount"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <FormNumberInput
                          py={0.5}
                          name="amount"
                          label="Ttl Amount"
                          placeholder="Ttl Amt"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {currencySymbol}
                              </InputAdornment>
                            ),
                            readOnly: true,
                          }}
                        />
                      </Grid>

                      {/* <Grid item xs={12}>
                        <FormInput
                          name="note[0]"
                          label="Note 1"
                          placeholder="Enter note 1"
                          py={0.5}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormInput
                          name="note[1]"
                          label="Note 2"
                          placeholder="Enter note 1"
                          py={0.5}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <FormInput
                      name="reference"
                      label="Reference"
                      placeholder="Enter invoice reference"
                      py={0.5}
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <FormInput
                      name="note"
                      label="Note"
                      placeholder="Enter invoice note"
                      py={0.5}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <FormInput
                      name="code"
                      label="Code"
                      py={0.5}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Copy">
                              <CopyToClipboard
                                text={values.code}
                                onCopy={() =>
                                  notify("Code copied successfully", "success")
                                }
                              >
                                <IconButton edge="end" size="small">
                                  <FileCopyOutlined />
                                </IconButton>
                              </CopyToClipboard>
                            </Tooltip>
                            &nbsp;&nbsp;
                            <Tooltip title="Reload">
                              <IconButton
                                size="small"
                                edge="end"
                                onClick={() =>
                                  setFieldValue("code", nanoid(10))
                                }
                              >
                                <ReplayOutlined />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Box>

                <Box mt={3} mb={1}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Grid item>
                      <TheButton
                        large
                        label="Reset"
                        customColor={theme.palette.secondary.main}
                        onClick={resetForm}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item>
                      <TheButton
                        large
                        label="Save"
                        customColor={theme.palette.success.main}
                        onClick={() => {
                          setFieldValue("submitType", "save");
                          handleSubmit();
                        }}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item>
                      <TheButton
                        large
                        label="Upload"
                        color="primary"
                        onClick={() => {
                          setFieldValue("submitType", "upload");
                          handleSubmit();
                        }}
                        loading={isSubmitting}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </div>
  );
}
