import React, { useState } from "react";
import Page from "../../components/SHARED/Page";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { a11yProps } from "../../components/SHARED/TabPanel";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { bankClientUrls } from "../../CONSTANTS/ClientUrls";
import { BankUrls } from "./config";
import { findIndex } from "lodash";
import { AppBar } from "@material-ui/core";
import { useEffect } from "react";

export default function Bank() {
  const location = useLocation();
  const [value, setValue] = useState(
    findIndex(BankUrls, { path: location.pathname })
  );
  let { path } = useRouteMatch();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(findIndex(BankUrls, { path: location.pathname }));
  }, [location.pathname]);

  return (
    <Page title="MHCO | Bank">
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        className="bg-white"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="full width tabs example"
          scrollButtons="auto"
          variant="scrollable"
        >
          {BankUrls.map((item, index) => (
            <Tab
              onClick={() => history.push(item.path)}
              label={item.label}
              key={index}
              value={index}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <Switch>
        <Route
          exact
          path={path}
          render={() => {
            return <Redirect to={BankUrls[0].path} />;
          }}
        />
        {BankUrls.map(({ component: Component, path }, index) => (
          <Route
            exact
            path={path}
            component={() => <Component value={value} index={path} />}
          />
        ))}
      </Switch>
    </Page>
  );
}
