import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./useStyles";
import BlueButton from "../Buttons/TheButton";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";
import nextId from "react-id-generator";

const _ = require("lodash");

export default function MaterialDialog({
  handleClose,
  open,
  actions,
  content,
  size,
  title,
}) {
  const classes = useStyles();
  const { issm, isxs } = useContext(AppContext);

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.dialog}
        fullScreen={issm || isxs}
        maxWidth={size}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>
          {title}
          {handleClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={classes.content}>{content}</DialogContent>
        <DialogActions className={classes.actions}>
          {!_.isEmpty(actions) &&
            actions.map((item, index) => (
              <BlueButton key={nextId()} {...item} />
            ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}
MaterialDialog.defaultProps = {
  size: "md",
};
