import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiToggleButton-root, & .MuiToggleButtonGroup-root": {
      width: "100%",
    },
    "& .MuiToggleButton-root:first-child": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& .MuiToggleButton-root:last-child": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
}));

export default useStyles;
