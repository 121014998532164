import { Icon } from "@iconify/react";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { get, isEmpty, upperCase } from "lodash";
import React, { useContext, useState } from "react";
import { useInvoiceSavedDataStore } from "../../../STORE/useInvoiceStore";
import { formatDate1, formatTime } from "../../../UTILS/date";
import OilTank from "../../SHARED/OilTank";
import fullTrash from "@iconify/icons-flat-color-icons/full-trash";
import TableListItemText from "../../SHARED/OilTank/TableListItemText";
import TheButton from "../../SHARED/Buttons/TheButton";
import { uploadMultipleInvoices } from "../../../ADAPTERS/invoice";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";
import { getImage } from "../../../CONSTANTS/Minions";

export default function Saved() {
  const { data, remove, reset } = useInvoiceSavedDataStore();
  const [loading, setLoading] = useState(false);
  const { notify } = useContext(AppContext);

  return (
    <Box m={3}>
      <Box mb={3}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <TheButton
              label="Upload All"
              color="primary"
              large
              loading={loading}
              disabled={isEmpty(data) || loading}
              onClick={() => {
                setLoading(true);
                uploadMultipleInvoices(data)
                  .then((res) => {
                    notify(res.message, "success");
                    reset();
                  })
                  .catch((err) => {
                    notify(err.message, "error");
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <OilTank
        title="Invoice Saved Data"
        data={data.map((item) => [
          item.date,
          item.occupancyType,
          get(item, "customer"),
          item.weight,
          item.tare,
          item.totalWeight,
          item.unitPrice,
          item.discount,
          item.amount,
          item.description,
          item.code,
        ])}
        columns={[
          {
            name: "Date | Time",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <TableListItemText
                    primary={formatDate1(value)}
                    secondary={formatTime(value)}
                  />
                );
              },
            },
          },
          {
            name: "Type",
            options: {
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Typography variant="subtitle2">{upperCase(value)}</Typography>
              ),
            },
          },
          {
            name: "Customer",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <ListItem className="p-0">
                    <ListItemAvatar>
                      <Avatar src={getImage(get(value, "image"))} />
                    </ListItemAvatar>
                    <TableListItemText
                      primary={value.name}
                      secondary={value.mobile}
                    />
                  </ListItem>
                );
              },
            },
          },
          "Weight",
          "Tare",
          "Total Weight",
          "Unit Price",
          "Discount",
          "Amount",
          "Description",
          {
            name: "Delete",
            options: {
              sort: false,
              filter: false,
              print: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <IconButton onClick={() => remove(value)}>
                  <Icon icon={fullTrash} fontSize={22} />
                </IconButton>
              ),
            },
          },
        ]}
        options={{
          pagination: false,
        }}
      />
    </Box>
  );
}
