import { bankClientUrls } from "../../CONSTANTS/ClientUrls";
import Form from "../../components/Bank/Form";
import Saved from "../../components/Bank/Saved";
import BankOil from "../../components/Bank/BankOil";
import Transactions from "../../components/Bank/Transactions";

export const BankUrls = [
  {
    label: "Form",
    path: bankClientUrls.form,
    component: Form,
  },
  {
    label: "Saved",
    path: bankClientUrls.saved,
    component: Saved,
  },
  {
    label: "All Banks",
    path: bankClientUrls.allBanks,
    component: BankOil,
  },
  {
    label: "Transactions",
    path: bankClientUrls.transactions,
    component: Transactions,
  },
];
