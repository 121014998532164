export const protectedUrl = "/app";

export const dashboardClientUrls = {
  base: `${protectedUrl}/dashboard`,
};

export const bankClientUrls = {
  base: `${protectedUrl}/bank`,
  form: `${protectedUrl}/bank/form`,
  saved: `${protectedUrl}/bank/saved`,
  allBanks: `${protectedUrl}/bank/all`,
  transactions: `${protectedUrl}/bank/transactions`,
};

export const invoiceClientUrls = {
  base: `${protectedUrl}/invoice`,
  form: `${protectedUrl}/invoice/form`,
  saved: `${protectedUrl}/invoice/saved`,
  allCustomers: `${protectedUrl}/invoice/customers`,
  transactions: `${protectedUrl}/invoice/transactions`,
};

export const paymentsClientUrls = {
  base: `${protectedUrl}/payments`,
  form: `${protectedUrl}/payments/form`,
  saved: `${protectedUrl}/payments/saved`,
};
