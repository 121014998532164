import React from "react";
import AppContext from "./AppContext";
import { useSnackbar } from "notistack";
import { useMediaQuery, useTheme } from "@material-ui/core";

const AppProvider = ({ children }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const notify = (message, type) => {
    enqueueSnackbar(message && message.toString(), { variant: type });
  };

  const islg = useMediaQuery(theme.breakpoints.up("lg"));
  const ismd = useMediaQuery(theme.breakpoints.only("md"));
  const issm = useMediaQuery(theme.breakpoints.only("sm"));
  const isxs = useMediaQuery(theme.breakpoints.only("xs"));

  const contextValue = {
    islg,
    ismd,
    issm,
    isxs,
    notify,
  };
  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export default AppProvider;
