import {
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import nextId from "react-id-generator";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  icon: {
    minWidth: "fit-content",
    marginRight: theme.spacing(2.5),
  },
  text: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "21px",
    color: theme.palette.grey[600],
  },
  menuPaper: {
    "& .MuiList-root": {
      padding: 0,
    },
  },
}));

export default function MoreMenu({ items }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={nextId()}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          horizontal: "left",
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            button
            onClick={() => {
              item.action();
              handleClose();
            }}
            key={index}
          >
            <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} className={classes.text} />
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
