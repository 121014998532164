import React from "react";
import { Skeleton } from "@material-ui/lab";
import useStyles from "./useStyles";

export default function InputLoading() {
  const classes = useStyles();
  return (
    <Skeleton
      variant="rect"
      className={classes.root}
      height="51px"
      animation="wave"
    />
  );
}
