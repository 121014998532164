// import React from 'react'

// export default function TheLayout({children}) {
//     return (
//         <div>asdasd
//             {children}
//         </div>
//     )
// }

import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Box, Paper } from "@material-ui/core";
import SidebarConfig from "./SidebarConfig";
import { Icon } from "@iconify/react";
import nextId from "react-id-generator";
import { Link, useHistory, useLocation } from "react-router-dom";

const drawerWidth = 240;
const scrollBarBackgroundColor = "#fff";
const scrollBarColor = "#ccc";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("lg")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("lg")]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   marginLeft: drawerWidth,
      display: "none",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("lg")]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   marginLeft: drawerWidth,
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2,2),
    display: "grid",
    gridTemplateRows: "minmax(30px, auto) minmax(0, 100%)",
    [theme.breakpoints.up("lg")]: {
      gridTemplateRows: "minmax(0, 100%)",
    },
    height: "100vh",
    // "&::-webkit-scrollbar": { width: "14px" },
    // "&::-webkit-scrollbar-track": {
    //   background: scrollBarBackgroundColor,
    //   borderRadius: "20px",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: scrollBarColor,
    //   borderRadius: "100px",
    //   border: "3px solid " + scrollBarBackgroundColor,
    // },
    width: "100%",
  },
}));

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Divider />

      <Box p={2}>
        <Box component={Paper} overflow="hidden">
          <ListItem button key={nextId()}>
            <ListItemIcon>
              <Avatar />
            </ListItemIcon>
            <ListItemText primary={"Farhan Syed"} />
          </ListItem>
        </Box>
      </Box>

      <Divider />
      <List component="div" disablePadding>
        {SidebarConfig.map((item, index) => (
          <Box key={index} py={0.5}>
            <ListItem
              button
              onClick={() => history.push(item.path)}
              selected={location.pathname.includes(item.path)}
              alignItems="center"
            >
              <ListItemIcon>
                <Icon icon={item.icon} fontSize={25} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          </Box>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            MHCO
          </Typography>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Toolbar />
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;
