import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./style";
import InputLoading from "../InputLoading";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Field } from "formik";
import nextId from "react-id-generator";

export default function FormToggle({
  name,
  label,
  placeholder,
  py,
  loading,
  options,
  value,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Box py={py} className={classes.root}>
      {loading ? (
        <InputLoading />
      ) : (
        <Field name="lastName">
          {({
            field, // { name, value, onChange, onBlur }
            form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => (
            <ToggleButtonGroup
              value={field.value}
              exclusive
              onChange={(event, newAlignment) => {
                if (newAlignment !== null) {
                  setFieldValue(name, newAlignment);
                }
              }}
              aria-label="text alignment"
            >
              {options.map((item, index) => (
                <ToggleButton
                  key={nextId()}
                  value={item.value}
                  style={{
                    background: value === item.value && item.color,
                    color: value === item.value && "#fff",
                  }}
                >
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </Field>
      )}
    </Box>
  );
}

FormToggle.defaultProps = {
  name: "",
  label: "",
  placeholder: "",
  py: 1,
  options: [],
};
