import { Icon } from "@iconify/react";
import { Box, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import { get, isEmpty, upperCase } from "lodash";
import React, { useContext, useState } from "react";
import { useBankSavedDataStore } from "../../../STORE/useBankStore";
import { formatDate1, formatTime } from "../../../UTILS/date";
import MoreMenu from "../../SHARED/MoreMenu";
import OilTank from "../../SHARED/OilTank";
import fullTrash from "@iconify/icons-flat-color-icons/full-trash";
import pencilIcon from "@iconify/icons-twemoji/pencil";
import TableListItemText from "../../SHARED/OilTank/TableListItemText";
import TheButton from "../../SHARED/Buttons/TheButton";
import { uploadTransactions } from "../../../ADAPTERS/banks";
import AppContext from "../../../CONTEXTS/AppContext/AppContext";

export default function Saved() {
  const { data, remove, reset } = useBankSavedDataStore();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { notify } = useContext(AppContext);

  return (
    <Box m={3}>
      <Box mb={3}>
        <Grid container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <TheButton
              label="Upload All"
              color="primary"
              large
              loading={loading}
              disabled={isEmpty(data) || loading}
              onClick={() => {
                setLoading(true);
                uploadTransactions(data)
                  .then((res) => {
                    notify(res.message, "success");
                    reset();
                  })
                  .catch((err) => {
                    notify(err.message, "error");
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <OilTank
        title="Bank Saved Data"
        data={data.map((item) => [
          item.date,
          item.transactionType,
          get(item, "bank.label"),
          item.amount,
          get(item, "currency.value"),
          item.payee,
          item.payeeBank,
          item.code,
        ])}
        columns={[
          {
            name: "Date | Time",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <TableListItemText
                    primary={formatDate1(value)}
                    secondary={formatTime(value)}
                  />
                );
              },
            },
          },
          {
            name: "Type",
            options: {
              sort: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <Typography
                  variant="subtitle2"
                  className={
                    value === "credit" ? "text-success" : "text-danger"
                  }
                >
                  {upperCase(value)}
                </Typography>
              ),
            },
          },
          {
            name: "Bank",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <TableListItemText
                    primary={value.split("||")[1]}
                    secondary={value.split("||")[0]}
                  />
                );
              },
            },
          },
          "Amount",
          "Currency",
          "Payee",
          { name: "Payee Bank" },
          {
            name: "Delete",
            options: {
              sort: false,
              filter: false,
              print: false,
              customBodyRender: (value, tableMeta, updateValue) => (
                <IconButton onClick={() => remove(value)}>
                  <Icon icon={fullTrash} fontSize={22} />
                </IconButton>
              ),
              // customBodyRender: (value, tableMeta, updateValue) => (
              //   <MoreMenu
              //     items={[
              //       {
              //         action: "",
              //         icon: <Icon icon={pencilIcon} fontSize={20} />,
              //         label: "Edit",
              //       },
              //       {
              //         action: () => remove(value),
              //         icon: <Icon icon={fullTrash} fontSize={22} />,
              //         label: "Delete",
              //       },
              //     ]}
              //   />
              // ),
            },
          },
        ]}
        options={{
          pagination: false,
        }}
      />
    </Box>
  );
}
